import React from "react";
import ContactForm from "./form";
import ContactImg from "../../images/Contact.png";

const ContactLanding = ({ title, subtitle, subtitle_two, email, image }) => {
  return (
    <>
      <div
        className={
          "flex flex-col lg:flex-row w-full px-10 py-5 sm:py-10 md:px-10 md:pb-20 lg:px-24 xl:px-32"
        }
      >
        <div className={"flex flex-col flex-1 pr-0 lg:pr-20 w-auto lg:w-1/2"}>
          <h1 className="leading-snug text-5xl xl:text-6xl xl:leading-snug font-bold pb-10 font-body">
            {title}
          </h1>
          <h2 className="text-l xl:text-xl font-lexend-deca pb-10">
            {subtitle}
          </h2>
          <img src={ContactImg} className={"w-90"} />
        </div>
        <ContactForm
          description={"Leave your details below and we'll be in touch."}
        />
      </div>
      <div className={"flex w-full flex-col justify-center items-center my-10"}>
        <h3 className="leading-snug text-xl lg:text-4xl font-bold font-body">
          {subtitle_two}
        </h3>
        <h3 className="leading-snug text-xl lg:text-4xl font-bold font-body text-blue pb-20">
          <a href={`mailto:${email}`}>{email}</a>
        </h3>
      </div>
    </>
  );
};

export default ContactLanding;
