import React from "react";

const ContactForm = ({ description, image }) => {
  return (
    <div className={"py-10 w-auto lg:w-1/2"}>
      <div
        className={
          "flex-col h-full border-4 rounded-3xl border-gray p-5 shadow-[10px_8px_0px_1px_rgba(211,211,211,1)] justify-center "
        }
      >
        <h2 className="text-l xl:text-3xl font-lexend-deca mt-12">
          {description}
        </h2>

        <form
          action="https://formsubmit.co/sydney@usydcommstem.org"
          method="POST"
        >
          <input
            type="hidden"
            name="_subject"
            value="Contact Information from website!"
          />
          <div className={"mt-10"}>
            <p className={"font-medium"}>Full name</p>
            <input
              type="text"
              name="name"
              className="w-full border-2 my-4 h-12 p-5 rounded-lg border-gray"
              required
            ></input>
          </div>
          <div className={"my-10"}>
            <p className={"font-medium"}>Email</p>
            <input
              type="email"
              name="email"
              className="w-full border-2 my-4 h-12 p-5 rounded-lg border-gray"
              required
            ></input>
          </div>
          <div className={"my-10"}>
            <p className={"font-medium"}>Message</p>
            <textarea
              type="text"
              name="message"
              className="w-full border-2 my-4 h-60 p-5 rounded-lg border-gray"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="bg-blue hover:bg-darkBlue text-white font-bold py-2 px-11 rounded-full"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
